<template>
  <div>
    <v-row>
        <v-col align="center">
        <v-text-field class="centered-input"
                v-model="search"
                append-icon="search"
                label="Search Module..."
                single-line
                hide-details
                style="width: 50%"
              ></v-text-field>
        </v-col>
      </v-row>
    <!-- <v-container fluid> -->
     
      <v-row dense>
        <v-col v-for="linkname in filteredSortSearchList" :key="linkname.name" :cols="linkname.flex">
          <router-link :to="{ name: linkname.name}" style="text-decoration: none">
            <v-card style="margin: 8px;" id="card-hover">
              <br />
              <v-img height="120px">
                <center>
                  <v-avatar class="avatar-icon" size="70">
                    <span>
                      <v-icon size="55" class="link-icon">{{linkname.icon}}</v-icon>
                    </span>
                  </v-avatar>
                  <v-card-title v-text="linkname.textval" class="card-title"></v-card-title>
                </center>
              </v-img>
              <v-card-actions>

                <v-avatar v-if="linkname.size==0" color="red darken-1" size="22">
                  <span class="white--text" :title="'#'+linkname.size+' Field Added'">{{linkname.size}}</span>
                </v-avatar>
                <v-avatar v-else color="success" size="22">
                  <span class="white--text" :title="'#'+linkname.size+' Field Added'">{{linkname.size}}</span>
                </v-avatar>
                <v-spacer></v-spacer>

                <router-link :to="{ name: linkname.name}" style="text-decoration: none">
                  <v-btn icon size="22">
                    <v-icon
                      :title="'Click here to Add '+linkname.textval"
                      class="add-plus-icon"
                    >mdi-plus-circle</v-icon>
                  </v-btn>
                </router-link>
              </v-card-actions>
            </v-card>
          </router-link>
        </v-col>
      </v-row>
    
  </div>
</template>

<script>
import axios from 'axios'
export default {
        
  data() {
    return {
      routerLinkList: [],
       search:'',
    };
  }, // end of data()
  mounted(){
       axios.get("Admin/adminSuperMaster")
    .then(res =>{
      //window.console.log("res"+res.data)
      if(res.data.msg=="200"){
        this.data = res.data
        this.routerLinkList=res.data.routerLinkList
        //console.log(this.data)  
      }
      else{
      }
    })
    .catch(error => {
        window.console.log(error)
    })
  },
  computed: {
    
    filteredSortSearchList(){
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }
      if (!this.search) return this.routerLinkList.sort(compare);
      
      return this.routerLinkList.filter(tbdata=>{
          return tbdata.name.toLowerCase().indexOf(this.search.toLowerCase())>=0       
      })
    }
  } // end of computed()

};
</script>
<style scoped>
.card-title{
font-weight: 400;
color: #9E9E9E;
}
.add-plus-icon{
        color: #616161
}
.avatar-icon {
  border: 1px solid #0000001a;
}
.link-icon {
  color: #bdbdbd;
}
#card-hover {
  transition: cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
#card-hover:hover {
  background: #ededed;
}
#card-hover:hover .link-icon {
  color: #3f51b5;
}
#card-hover:hover .avatar-icon {
  border-color: #3f51b5;
  background: white;
}
#card-hover:hover .card-title {
   color: #777777;
   
}
#card-hover:hover .add-plus-icon {
  
   border: none;
   border-radius: 100%; 
   color: white;
   background:#3f51b5;
}
</style>